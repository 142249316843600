import { gql, useQuery } from "@apollo/client";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetNextBusinessDay } from "api/useGetNextBusinessDay";
import moment from "moment";

const GET_CONTACTS_QUERY = gql`
  query GetPortfolioAccountBalances($contactId: String, $today: String, $settlementStartDateT1: String, $settlementEndDateT1: String, $settlementStartDateT2: String) {
    contacts(contactId: $contactId) {
      name
      portfolios {
        id
        name
        shortName
        currency {
          securityCode
          amountDecimalCount
          __typename
        }
        accounts {
          id
          name
          number2
          currency {
            securityCode
            __typename
          }
          __typename
        }
        portfolioReport(
          reportDate: $today
          calculateExpectedAmountBasedOpenTradeOrders: true
          calculateExpectedAmountBasedUnsettledTransactions: true
          useLatestPositionsAmount: true
          adjustPositionsBasedOnOpenTradeOrders: false
        ) {
          accountItems {
            accountId
            portfolioId
            currency {
              securityCode
              __typename
            }
            accountName
            amountBeforeUnsettledTransactions
            expectedAmount
            amountAfterOpenTradeOrders
            balanceAccCurr
            buyOpenTradeOrder: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "4"
            ) {
              buyOpenTradeAmount: tradeAmount
              __typename
            }
            buyAcceptedTradeOrder: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "5"
            ) {
              buyAcceptedTradeAmount: tradeAmount
              __typename
            }
            buyExecutableTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "1"
            ) {
              buyExecutableTradeAmount: tradeAmount
              __typename
            }
            buySentToExecutionTradeOrder: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "10"
            ) {
              buySentToExecutionTradeAmount: tradeAmount
              __typename
            }
            buyInExecutionTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "6"
            ) {
              buyInExecutionTradeAmount: tradeAmount
              __typename
            }
            buyPartiallyExecutedTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "12"
            ) {
              buyPartiallyExecutedTradeAmount: tradeAmount
              __typename
            }
            buyExecutedMarketTradeOrders: tradeOrders(
              typeCodes: ["B", "SUB"]
              aggregationMode: 2
              orderStatus: "7"
            ) {
              buyExecutedMarketTradeAmount: tradeAmount
              __typename
            }
            sellSettlementDoneTransactionsT1: transactions(
              aggregationMode: 2
              settlementStartDate: $settlementStartDateT1
              settlementEndDate: $settlementEndDateT1
              typeCodes: ["S", "RED"]
              tags: "Settlement-Done"
            ) {
              sellSettlementDoneTransactionAmountT1: tradeAmount
              __typename
            }
            sellSettlementDoneTransactionsT2: transactions(
              aggregationMode: 2
              settlementStartDate: $settlementStartDateT2
              typeCodes: ["S", "RED"]
              tags: "Settlement-Done"
            ) {
              sellSettlementDoneTransactionAmountT2: tradeAmount
              __typename
            }
            sellSettlementWaitingTransactionsT1: transactions(
              aggregationMode: 2
              settlementEndDate: $settlementEndDateT1
              typeCodes: ["S", "RED"]
              tags: "Settlement-Waiting"
            ) {
              sellSettlementWaitingTransactionAmountT1: tradeAmount
              __typename
            }
            sellSettlementWaitingTransactionsT2: transactions(
              aggregationMode: 2
              settlementStartDate: $settlementStartDateT2
              typeCodes: ["S", "RED"]
              tags: "Settlement-Waiting"
            ) {
              sellSettlementWaitingTransactionAmountT2: tradeAmount
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

interface Currency {
  securityCode: string;
  amountDecimalCount?: number;
  __typename: string;
}

interface Account {
  id: string;
  name: string;
  number2: string;
  currency: Currency;
  __typename: string;
}

interface AccountItem {
  accountId: string;
  portfolioId: string;
  currency: Currency;
  accountName: string;
  amountBeforeUnsettledTransactions: number;
  expectedAmount: number;
  amountAfterOpenTradeOrders: number;
  balanceAccCurr: number;
  buyOpenTradeOrder: { buyOpenTradeAmount: number }[];
  buyAcceptedTradeOrder: { buyAcceptedTradeAmount: number }[];
  buyExecutableTradeOrders: { buyExecutableTradeAmount: number }[];
  buySentToExecutionTradeOrder: { buySentToExecutionTradeAmount: number }[];
  buyInExecutionTradeOrders: { buyInExecutionTradeAmount: number }[];
  buyPartiallyExecutedTradeOrders: { buyPartiallyExecutedTradeAmount: number }[];
  buyExecutedMarketTradeOrders: { buyExecutedMarketTradeAmount: number }[];
  sellSettlementDoneTransactionsT1: { sellSettlementDoneTransactionAmountT1: number }[];
  sellSettlementDoneTransactionsT2: { sellSettlementDoneTransactionAmountT2: number }[];
  sellSettlementWaitingTransactionsT1: { sellSettlementWaitingTransactionAmountT1: number }[];
  sellSettlementWaitingTransactionsT2: { sellSettlementWaitingTransactionAmountT2: number }[];
  __typename: string;
}

interface PortfolioReport {
  accountItems: AccountItem[];
  __typename: string;
}

interface Portfolio {
  id: number;
  name: string;
  shortName: string;
  currency: Currency;
  accounts: Account[];
  portfolioReport: PortfolioReport;
  __typename: string;
}

interface Contact {
  name: string;
  portfolios: Portfolio[];
  __typename: string;
}

export interface ContactsData {
  contacts: Contact[];
}


export interface Balance {
  trading: { currency: string, amount: number }[];
  withdrawal: { currency: string, amount: number }[];
}
interface Balances { 
  [key: string]: Balance
}

export const useGetAvailableBalancesExperimental = () => {
  const { data: contactData, loading:loadingContactInfo } = useGetContactInfo();

  const { data: calendarNextBusinessDay, loading: loadingBd1 } = useGetNextBusinessDay();
  const { data: calendaDATBusinessDay , loading: loadingBd2} = useGetNextBusinessDay(calendarNextBusinessDay); //day after tomorow

  const addBusinessDays = (date: Date, daysToAdd: number) => {
    const WEEKEND = [moment().day("Saturday").weekday(), moment().day("Sunday").weekday()]
    let daysAdded = 0;
    let momentDate = moment(new Date(date));
    while (daysAdded < daysToAdd) {
      momentDate = momentDate.add(1, 'days');
      if (!WEEKEND.includes(momentDate.weekday())) {
        daysAdded++
      }
    }

    return momentDate;
  }

  const currentDate = new Date();
  const nextBusinessDay = addBusinessDays(currentDate, 1);
  const settlementStartDate = addBusinessDays(currentDate, 2);
  
  const variables: { 
    contactId: string | undefined; 
    today: string; 
    settlementStartDateT1: string; 
    settlementEndDateT1: string; 
    settlementStartDateT2: string; 
  } = {
    contactId : contactData?._contactId,
    today: currentDate.toISOString().split('T')[0],
    settlementStartDateT1: calendarNextBusinessDay ?? nextBusinessDay.format('YYYY-MM-DD'),
    settlementEndDateT1: calendarNextBusinessDay ?? nextBusinessDay.format('YYYY-MM-DD'),
    settlementStartDateT2: calendaDATBusinessDay ?? settlementStartDate.format('YYYY-MM-DD'),
  };

  const { loading, error, data, refetch } = useQuery<ContactsData>(GET_CONTACTS_QUERY, {
    variables: variables,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const balances:Balances = {}

  data?.contacts.forEach((contact: Contact) => {
    contact?.portfolios?.forEach((portfolio: Portfolio) => {
      portfolio?.portfolioReport?.accountItems.forEach((item: AccountItem) => {
        const accountNumber = item.accountName.split('-')[0];
        const currency = item.currency.securityCode;

        const tradingAmount = item.balanceAccCurr - (
          (item.buyOpenTradeOrder[0]?.buyOpenTradeAmount || 0) 
          + (item.buyAcceptedTradeOrder[0]?.buyAcceptedTradeAmount || 0)
          + (item.buyExecutableTradeOrders[0]?.buyExecutableTradeAmount || 0)
          + (item.buySentToExecutionTradeOrder[0]?.buySentToExecutionTradeAmount || 0)
          + (item.buyInExecutionTradeOrders[0]?.buyInExecutionTradeAmount || 0)
          + (item.buyPartiallyExecutedTradeOrders[0]?.buyPartiallyExecutedTradeAmount || 0)
          + (item.buyExecutedMarketTradeOrders[0]?.buyExecutedMarketTradeAmount || 0)
          + (item.sellSettlementDoneTransactionsT2[0]?.sellSettlementDoneTransactionAmountT2 || 0)
          + (item.sellSettlementWaitingTransactionsT2[0]?.sellSettlementWaitingTransactionAmountT2 || 0)
        );

        const withdrawalAmount = tradingAmount 
          - (item.sellSettlementDoneTransactionsT1[0]?.sellSettlementDoneTransactionAmountT1 || 0) 
          - (item.sellSettlementWaitingTransactionsT1[0]?.sellSettlementWaitingTransactionAmountT1 || 0);

        if (!balances[accountNumber]) {
          balances[accountNumber] = { 
            trading: [],
            withdrawal: [] 
          };
        }

        balances[accountNumber].trading.push({ currency, amount: tradingAmount });
        balances[accountNumber].withdrawal.push({ currency, amount: withdrawalAmount });
      });
    });
  });

  if (contactData?.accountsCurrencies) {
    Object.keys(contactData?.accountsCurrencies).forEach(accountNumber => {
      contactData?.accountsCurrencies[accountNumber].forEach(currency => {
        if (!balances[accountNumber]) {
          balances[accountNumber] = { 
            trading: [],
            withdrawal: [] 
          };
        }
  
        if (!balances[accountNumber].trading.find((item) => item.currency === currency)) {
          balances[accountNumber].trading.push({ currency, amount: 0 });
        }
  
        if (!balances[accountNumber].withdrawal.find((item) => item.currency === currency)) {
          balances[accountNumber].withdrawal.push({ currency, amount: 0 });
        }
      });
    });
  }

  const combinedLoading: boolean = loadingContactInfo || loadingBd1 || loadingBd2 || loading;

  return {
    loading: combinedLoading,
    error,
    data,
    balances,
    refetch,
  };
};


