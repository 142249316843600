import { gql } from "@apollo/client";

export const TRADE_ORDERS_DETAILS = gql`
  fragment TradeOrdersDetails on Transaction {
    id
    amount
    orderStatus
    securityName
    security {
      isinCode
    }
    securityTypeCode
    intInfo
    type {
      typeCode
      typeName
      typeNamesAsMap
      cashFlowEffect
      amountEffect
    }
    transactionDate
    tradeTime
    receiveDate
    receiveTime
    tradeAmountInPortfolioCurrency
    parentPortfolio {
      id
    }
    reference
    extId
    linkedTransaction {
      id
      amount
      orderStatus
      securityName
      type {
        typeCode
        typeName
        typeNamesAsMap
        cashFlowEffect
        amountEffect
      }
      transactionDate
      tradeAmountInPortfolioCurrency
      parentPortfolio {
        id
      }
      reference
      extId
    }
    tags
    tagsAsSet
    cost
    cost2
    tax
    tax2
    accruedInterest
    fxRateView
    reportFxRate
  }
`;

export const TRADE_ORDERS_EXTENDED_DETAILS = gql`
  fragment TradeOrderExtendedDetails on Transaction {
    id
    tags
    fxRateView
    amount
    security {
      id
      type {
        name
        code
      }
      isinCode
      country {
        code
      }
      exchange {
        id
        name
      }
    }
    settlementDate
    unitPriceInSecurityCurrency: unitPriceView
    costInSecurityCurrency: totalCost
    cost
    cost2
    tax
    tax2
    accruedInterest
    accountFxRate: accountFxRateView
    documents(filterTags: $filterTags) {
      identifier
    }
    extInfo
    marketPlace {
      id
      name
    }
    account {
      currency {
        accountCurrencyCode: securityCode
      }
      name
    }
    securityCurrencyCode: currencyCode
    tradeAmountInAccountCurrency
    tradeAmountInSecurityCurrency: tradeAmount
    grossPriceInSecurityCurrency: grossPrice
    grossPriceInAccountCurrency
    orderStatus
    linkedTransaction {
      id
      amount
      security {
        id
        isinCode
        country {
          code
        }
        exchange {
          id
          name
        }
      }
      settlementDate
      unitPriceInSecurityCurrency: unitPriceView
      costInSecurityCurrency: totalCost
      cost
      cost2
      tax
      tax2
      accountFxRate: accountFxRateView
      documents(filterTags: $filterTags) {
        identifier
      }
      extInfo
      marketPlace {
        id
        name
      }
      account {
        currency {
          accountCurrencyCode: securityCode
        }
      }
      securityCurrencyCode: currencyCode
      tradeAmountInAccountCurrency
      tradeAmountInSecurityCurrency: tradeAmount
      grossPriceInSecurityCurrency: grossPrice
      grossPriceInAccountCurrency
      orderStatus
    }
  }
`;
