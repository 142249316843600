import { gql, useQuery } from "@apollo/client";

const GET_PORTFOLIO_OVERVIEW = gql`
  query GetPortfolioOverview($portfolioIds: [Long]) {
    portfoliosByIds(ids: $portfolioIds) {
      portfolioReport(
        calculateExpectedAmountBasedOpenTradeOrders: true
        calculateExpectedAmountPessimistically: true
      ) {
        portfolioId
        portfolioReportItems {
          security {
            id
            securityCode
            name
            __typename
          }
          amount
          amountFromOpenTradeOrders
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

interface PortfolioSellPendingData {
  portfoliosByIds: {
    portfolioReport: {
      portfolioId: number;
      portfolioReportItems: {
        security: {
          id: number;
          securityCode: string;
          name: string;
        };
        amount: number;
        amountFromOpenTradeOrders: number;
      }[];
    };
  }[];
}

export const useGetPortfolioSellPending = (
  portfolioId: number | undefined,
) => {
  const { loading, error, data } = useQuery<PortfolioSellPendingData>(GET_PORTFOLIO_OVERVIEW, {
    variables: {
      portfolioIds: [portfolioId],
    },
    notifyOnNetworkStatusChange: true,
    skip: !portfolioId,
    fetchPolicy: "no-cache",
  });

  return {
    loading,
    error,
    data: data,
    reportItems: data?.portfoliosByIds[0]?.portfolioReport?.portfolioReportItems,
  };
};
