/* eslint-disable react-hooks/rules-of-hooks */
import { MutableRefObject, useEffect, useMemo, useState } from "react";
import { ExecutionMethod } from "api/enums";
import { SecurityTypeCode } from "api/holdings/types";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetPortfolioSellPending } from "api/trading/useGetPortfolioSellPending";
import { useGetSellData } from "api/trading/useGetSellData";
import { useTrade } from "api/trading/useTrade";
import {
  PortfolioSelect,
  DownloadableDocument,
  Button,
  Input,
  LabeledDiv,
  LoadingIndicator,
  Select,
  KIDTooltip,
} from "components/index";
import { LabeledDivFlex } from "components/LabeledDiv/LabeledDivFlex";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { getBackendTranslation } from "utils/backTranslations";
import { handleNumberInputEvent, handleNumberPasteEvent, isValidInputByStep } from "utils/input";
import { round, roundDown } from "utils/number";
import {
  getBlockSizeErrorTooltip,
  getBlockSizeMinTradeAmount,
  getTradeAmountTooltip,
} from "utils/trading";
import { addProtocolToUrl } from "utils/url";
import { useGetSecurityDetails } from "../../../api/holdings/useGetSecurityDetails";
import { filterJustBrokerage } from "../../../services/permissions/usePermission";
import { useOrderTypeFields, EOrderType } from "../useOrderTypeFields";
import { useTradablePortfolioSelect } from "../useTradablePortfolioSelect";
import { useGetSellTradeType } from "./useGetSellTradeType";

export interface SellModalInitialData {
  id: number;
  securityCode: string | null;
}

interface SellModalProps extends SellModalInitialData {
  modalInitialFocusRef: MutableRefObject<null>;
  onClose: () => void;
}

const FALLBACK_DECIMAL_COUNT = 2;

const getUnitsToSell = (
  isTradeInUnits: boolean,
  isPercentageMode: boolean,
  inputAsNr: number,
  securityPriceInPfCurrency: number,
  units: number,
  securityAmountDecimalCount: number
) => {
  if (isPercentageMode) {
    return roundDown((inputAsNr / 100) * units, securityAmountDecimalCount);
  } else {
    if (isTradeInUnits) {
      return roundDown(inputAsNr, securityAmountDecimalCount);
    } else {
      return roundDown(
        inputAsNr / (securityPriceInPfCurrency || 1),
        securityAmountDecimalCount
      );
    }
  }
};

export const isSecurityTypeFund = (
  securityType: SecurityTypeCode | undefined
) => securityType === SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE;

const getTradeType = (securityType: SecurityTypeCode | undefined) =>
  isSecurityTypeFund(securityType) ? "redemption" : "sell";

export const SellModalContent = ({
  modalInitialFocusRef,
  onClose,
  id: securityId,
  securityCode
}: SellModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [input, setInput] = useState("");
  const inputAsNr = input ? parseFloat(input) : 0;
  const isPercentageMode = false;

  const { t, i18n } = useModifiedTranslation();
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfolios } = { portfolios: [] } } = useGetContactInfo(
    false,
    selectedContactId
  );
  const { portfolioId, setPortfolioId, portfolioOptions } =
    useTradablePortfolioSelect();

  const filteredPortfolioOptions = filterJustBrokerage(portfolioOptions);
  const selectedPortfolioId = portfolioId;

  const selectedPortfolio = useMemo(
    () => portfolios.find((p) => p.id === portfolioId),
    [portfolioId, portfolios]
  );


  const { reportItems: sellPendingReportItems, loading: loadingSellPending } = useGetPortfolioSellPending(selectedPortfolio?.id)

  const { data: security, loading: loadingSecurity } = useGetSecurityDetails(
      securityCode?.toString() ?? securityId?.toString(),
      selectedPortfolio?.currency?.securityCode,
      !!securityCode
  );

  const { loading: loadingPfReport, data: sellData } = useGetSellData(
    selectedPortfolioId,
    security?.currency.securityCode
  );

  const securityTypeCode = security?.type?.code;

  const holding = sellData?.portfolio?.portfolioReport?.holdingPositions.find(
    (h) => h?.security?.id === securityId
  );
  const marketValue = holding?.marketValue;

  const units = sellPendingReportItems?.find((item) => item.security.id === securityId)?.amount || holding?.amount;

  const defaultAccount =
    sellData?.portfolio?.accounts?.find(
      (a) =>
        a?.currency?.securityCode ===
        sellData?.portfolio?.currency?.securityCode
    ) || sellData?.portfolio?.accounts?.[0];

  const accountToSecurityFxRate = 1 / (defaultAccount?.currency?.fxRate || 1);

  const { isTradeInUnits, canToggleTradeType, setIsTradeInUnits } =
    useGetSellTradeType(security?.tagsAsSet, securityTypeCode);

  const portfolioCurrencyAmountDecimalCount =
    selectedPortfolio?.currency?.amountDecimalCount !== undefined
      ? selectedPortfolio?.currency?.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const securityAmountDecimalCount =
    security?.amountDecimalCount !== undefined
      ? security?.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const securityCurrencyAmountDecimalCount =
    security?.currency?.amountDecimalCount !== undefined
      ? security?.currency?.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const accountCurrencyAmountDecimalCount =
    defaultAccount?.currency?.amountDecimalCount !== undefined
      ? defaultAccount?.currency?.amountDecimalCount
      : FALLBACK_DECIMAL_COUNT;
  const percentagDecimalCount = FALLBACK_DECIMAL_COUNT;

  const securityToPortfolioFxRate = 1; //security?.fxRate || 1;
  const securityPrice = security?.latestMarketData?.price;
  const securityPriceInPfCurrency =
    securityPrice !== undefined
      ? round(
          securityPrice * securityToPortfolioFxRate,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;
  const securityPriceInAccCurrency =
    securityPrice !== undefined
      ? round(
          securityPrice * accountToSecurityFxRate,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;

  const unitsToSell =
    securityPriceInPfCurrency !== undefined && units !== undefined
      ? getUnitsToSell(
          isTradeInUnits,
          isPercentageMode,
          inputAsNr,
          securityPriceInPfCurrency,
          units,
          securityAmountDecimalCount
        )
      : undefined;

  const { orderType, setOrderType, orderTypeOptions,
    orderExpiration, setOrderExpiration, orderExpirationOptions
    , unitPrice, setUnitPrice, getFormattedOrderTypeFields, isOrderTypeFieldsVisible } = useOrderTypeFields();


  const [stringUnitPrice, setStringUnitPrice] = useState<string>("");

  useEffect(() => {
    setUnitPrice(+stringUnitPrice);
  }, [setUnitPrice, stringUnitPrice]);

  let estimatedTradeAmountInPfCurrency =
  unitsToSell !== undefined && securityPriceInPfCurrency !== undefined
    ? round(
        unitsToSell * securityPriceInPfCurrency,
        portfolioCurrencyAmountDecimalCount
      )
    : undefined;

  //------estimateTradeAmount variations -----
  if (unitPrice && securityPrice && estimatedTradeAmountInPfCurrency && security?.type?.code === SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE) {
    estimatedTradeAmountInPfCurrency = round(
      (+inputAsNr * +(unitPrice || 0)),
      portfolioCurrencyAmountDecimalCount
    );
  }

  if (securityPrice && estimatedTradeAmountInPfCurrency && security?.type?.code === SecurityTypeCode.DEBT_INSTRUMENT) {
    if (orderType?.id === EOrderType.LIMIT_ORDER) {
      estimatedTradeAmountInPfCurrency = round(
        (+inputAsNr * +(unitPrice || 0)) / 100,
        portfolioCurrencyAmountDecimalCount
      );
    } else {
      estimatedTradeAmountInPfCurrency = round(
        (+inputAsNr * +securityPrice) / 100,
        portfolioCurrencyAmountDecimalCount
      );
    }
  }

  if (securityTypeCode && securityPrice && estimatedTradeAmountInPfCurrency 
    && orderType?.id === EOrderType.LIMIT_ORDER
    && [SecurityTypeCode.EQUITY, SecurityTypeCode.ETF].includes(securityTypeCode)) {
      estimatedTradeAmountInPfCurrency = round(
        (+inputAsNr * +(unitPrice || 0)),
        portfolioCurrencyAmountDecimalCount
      );
  }
  //-----------------

  const estimatedTradeAmountInSecurityCurrency =
    unitsToSell !== undefined && securityPrice !== undefined
      ? round(unitsToSell * securityPrice, securityCurrencyAmountDecimalCount)
      : undefined;

  const estimatedTradeAmountInAccountCurrency =
    unitsToSell !== undefined && securityPriceInAccCurrency !== undefined
      ? round(
          unitsToSell * securityPriceInAccCurrency,
          accountCurrencyAmountDecimalCount
        )
      : undefined;

  const calculatedReportfxRate =
    (estimatedTradeAmountInSecurityCurrency || 0) /
    (estimatedTradeAmountInPfCurrency || 1);

  const calculatedAccountFxRate =
    (estimatedTradeAmountInSecurityCurrency || 0) /
    (estimatedTradeAmountInAccountCurrency || 1);

  const securityName =
    security !== undefined
      ? getBackendTranslation(
          security?.name ?? "",
          security?.namesAsMap,
          i18n.language
        )
      : "-";

  const intInfoList = [];
  intInfoList.push(`OrderLanguage=${i18n.language}`);
  intInfoList.push(`OrigTradeAmount=${estimatedTradeAmountInPfCurrency}`);

  const { handleTrade: handleSell } = useTrade({
    tradeType: getTradeType(securityTypeCode),
    portfolio:
      portfolios.find((portfolio) => portfolio.id === portfolioId) ||
      portfolios[0],
    securityName,
    units: isTradeInUnits ? unitsToSell : undefined,
    tradeAmount: !isTradeInUnits
      ? estimatedTradeAmountInSecurityCurrency
      : undefined,
    securityCode: security?.securityCode || "",
    executionMethod: isTradeInUnits
      ? ExecutionMethod.UNITS
      : ExecutionMethod.NET_TRADE_AMOUNT,
    reportFxRate: calculatedReportfxRate,
    accountFxRate: calculatedAccountFxRate,
    intInfo: intInfoList.join(";"),
    ...getFormattedOrderTypeFields(securityTypeCode)
  });

  const portfolioCurrency = security?.currency?.securityCode;

  const { readonly } = useKeycloak();

  const loading = loadingPfReport && loadingSecurity && loadingSellPending;

  const tradeAmountTooltip = () => {
    if (security?.type?.code === SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE) {
      return t("switchOrderModal.tradeAmountDisclaimer", {
        price: security?.latestMarketData?.price ? `${security?.latestMarketData?.price} ${security.currency.securityCode}` : undefined,
        date: security.latestMarketData?.date !== undefined
        ? new Date(security.latestMarketData?.date).toLocaleDateString(i18n.language, {
            dateStyle: "medium",
          })
        : undefined,
      }); 
    }

    if ((security?.type?.code === SecurityTypeCode.EQUITY 
      || security?.type?.code === SecurityTypeCode.ETF) 
      && orderType?.id === EOrderType.LIMIT_ORDER) 
    {
        return undefined;
    }

    if (security?.type?.code === SecurityTypeCode.DEBT_INSTRUMENT 
      && [EOrderType.LIMIT_ORDER, EOrderType.MARKET_ORDER].includes(orderType?.id as EOrderType))
    {
      return undefined;
    }

    if (unitsToSell !== undefined &&
      security !== undefined &&
      security &&
      portfolioCurrency !== undefined) {
        return getTradeAmountTooltip(
          unitsToSell,
          security,
          securityToPortfolioFxRate,
          portfolioCurrency,
          i18n.language,
          t
        );
    }

    return undefined;
  }

  const blockSizeMinTradeAmountInPfCurrency =
    securityPrice !== undefined
      ? round(
          getBlockSizeMinTradeAmount(
            securityAmountDecimalCount,
            securityPrice
          ) * securityToPortfolioFxRate,
          portfolioCurrencyAmountDecimalCount
        )
      : undefined;

  const blockSizeTradeAmountError =
    !isTradeInUnits &&
    inputAsNr > 0 &&
    security !== undefined &&
    security &&
    blockSizeMinTradeAmountInPfCurrency !== undefined &&
    portfolioCurrency !== undefined &&
    estimatedTradeAmountInPfCurrency !== undefined &&
    blockSizeMinTradeAmountInPfCurrency > estimatedTradeAmountInPfCurrency
      ? getBlockSizeErrorTooltip(
          blockSizeMinTradeAmountInPfCurrency,
          security,
          security.fxRate,
          portfolioCurrency,
          i18n.language,
          t,
          false
        )
      : undefined;

  const emptyPortfolio = units === undefined || units === 0;

  const insufficientFunds =
    unitsToSell !== undefined && units !== undefined && unitsToSell > units;

  //however many decimals to round the input to
  const INPUT_BLOCK_SIZE = isPercentageMode
    ? percentagDecimalCount
    : isTradeInUnits
    ? securityAmountDecimalCount
    : portfolioCurrencyAmountDecimalCount;

  useEffect(() => {
    //when switching between amount and trade amount
    //we must make sure the input is rounded properly
    setInput((currInput) => {
      return currInput
        ? round(parseFloat(currInput), INPUT_BLOCK_SIZE).toString()
        : "";
    });
  }, [INPUT_BLOCK_SIZE]);

  const minimalNominalValue = securityTypeCode === SecurityTypeCode.DEBT_INSTRUMENT 
    && security?.minTradeAmount ? security.minTradeAmount : undefined;

  const stepNominalValue = securityTypeCode === SecurityTypeCode.DEBT_INSTRUMENT 
    && security?.blockSize ? security.blockSize : undefined;

  // comment for now, not sure if we need it
  
  // const amountInputLabel = () => {
  //   if (!isPercentageMode && securityTypeCode === SecurityTypeCode.DEBT_INSTRUMENT) {
  //     return minimalNominalValue 
  //               ? `${t("tradingModal.valueInputLabel")} (${t('tradingList.minimalNominalValue')}:${minimalNominalValue})` 
  //               : t("tradingModal.valueInputLabel");
  //   }

  //   return !isPercentageMode && !isTradeInUnits && isOrderTypeFieldsVisible(securityTypeCode)
  //       ? t("tradingModal.valueInputLabel")
  //       : isPercentageMode && isTradeInUnits
  //           ? t("tradingModal.shareOfUnitsInputLabel")
  //           : isPercentageMode && !isTradeInUnits
  //               ? t("tradingModal.shareOfTradeAmountInputLabel")
  //               : isTradeInUnits
  //                   ? t("tradingModal.unitsInputLabel")
  //                   : t("tradingModal.tradeAmountSimpleInputLabel")
  // }

  const amountInputLabel = () => {
    switch (securityTypeCode) {
        case SecurityTypeCode.EQUITY:
            return t("transactionsPage.numberOfShares");
        case SecurityTypeCode.DEBT_INSTRUMENT:
            return minimalNominalValue 
              ? `${t("transactionsPage.nominalValue")} (${t('tradingList.minimalNominalValue')}:${minimalNominalValue})` 
              : t("transactionsPage.nominalValue");
        default:
            return t("transactionsPage.quantity");
    }
  };

  const priceAsInteger = securityTypeCode && [
    SecurityTypeCode.EQUITY, 
    SecurityTypeCode.ETF,
  ].includes(securityTypeCode);

  const disableSellButton = () => {
    return (
      readonly ||
      inputAsNr === 0 ||
      loading ||
      !portfolioId ||
      insufficientFunds ||
      !!blockSizeTradeAmountError ||
      emptyPortfolio ||
      submitting ||
      (priceAsInteger && Number.isInteger(inputAsNr) === false) ||
      !!(minimalNominalValue && inputAsNr < minimalNominalValue) ||
      !!(stepNominalValue && minimalNominalValue && inputAsNr >= minimalNominalValue && (inputAsNr % stepNominalValue) !== 0)
    );
  };


  const handleInputErrors = ():string => {

    if (input && inputAsNr > 0) {
      if (priceAsInteger && Number.isInteger(inputAsNr) === false) {
        return t("tradingModal.tradeAmountIntegerError");
      }

      if (insufficientFunds && isTradeInUnits) {
        return t("tradingModal.insufficientUnitsError");
      }

      if (insufficientFunds && !isTradeInUnits) {
        return t("tradingModal.insufficientMarketValueError");
      }

      if (minimalNominalValue && inputAsNr < minimalNominalValue) {
        return t("tradingModal.minimalNominalValueError");
      }

      if (stepNominalValue && minimalNominalValue && inputAsNr >= minimalNominalValue && !isValidInputByStep(inputAsNr, stepNominalValue) ) {
        return t("tradingModal.stepNominalValueError", {
          step: stepNominalValue,
        });
      }
    }

    return "";
  }

  const hideTooltipVisible = [
    SecurityTypeCode.COLLECTIVE_INVESTMENT_VEHICLE, 
    SecurityTypeCode.ETF
  ].includes(securityTypeCode as SecurityTypeCode)
    && security?.tagsAsSet.includes("PROF-Yes");

  return (
    <div className="grid gap-2 min-w-[min(84vw,_375px)]">
      {!loadingSecurity && securityName && (
        <LabeledDiv
          label={t("tradingModal.securityName")}
          className="max-w-24ch text-2xl font-semibold"
        >
          {securityName ?? "-"}
        </LabeledDiv>
      )}
      {loadingSecurity && <LoadingIndicator size="sm" />}

      {security?.url2 && (
        <div className="flex items-center w-fit">
          <DownloadableDocument
            url={addProtocolToUrl(security.url2)}
            label={t("tradingModal.kiid")}
          />
          {!hideTooltipVisible && <KIDTooltip />}
        </div>
      )}
      <div className="z-10">
        <PortfolioSelect
          portfolioOptions={filteredPortfolioOptions}
          portfolioId={portfolioId}
          onChange={(newPortfolio) => setPortfolioId(newPortfolio.id)}
          label={t("tradingModal.portfolio")}
          error={!portfolioId ? t("tradingModal.selectPortfolioError") : ""}
        />
      </div>
      {!loadingPfReport && isTradeInUnits && (
        <LabeledDiv
          label={t("tradingModal.currentUnits")}
          className="text-xl font-semibold text-gray-700"
        >
          {portfolioCurrency !== undefined && units !== undefined
            ? t("number", { value: units })
            : "0"}
        </LabeledDiv>
      )}

      {!loadingPfReport && !isTradeInUnits && (
        <LabeledDiv
          label={t("tradingModal.currentMarketValue")}
          className="text-xl font-semibold text-gray-700"
        >
          {marketValue !== undefined && portfolioCurrency !== undefined
            ? t("numberWithCurrency", {
                value: marketValue,
                currency: portfolioCurrency,
              })
            : "0"}
        </LabeledDiv>
      )}
      {loadingPfReport && <LoadingIndicator size="sm" />}
      <Input
        disabled={!portfolioId}
        ref={modalInitialFocusRef}
        value={input}
        onChange={(event) => {
          handleNumberInputEvent(
            event,
            setInput,
            0,
            undefined,
            INPUT_BLOCK_SIZE
          );
        }}
        onPaste={(event) => {
          handleNumberPasteEvent(event, setInput, 0, undefined, INPUT_BLOCK_SIZE);
        }}
        label={amountInputLabel()}
        type="text"
        onKeyDown={(event) => {
          if (priceAsInteger && (event.key === '.' || event.key === ',')) {
            event.preventDefault();
          }

          const input = event.target as HTMLTextAreaElement;
          const value = input.value;
          const key = event.key;
      
          //prevent round up decimals
          if (!isNaN(+key) && key !== ' ') {
            const selectionStart = input.selectionStart;
            const selectionEnd = input.selectionEnd;
    
            // Check if the entire input value is selected
            if (selectionStart === 0 && selectionEnd === value.length) {
                return; // Allow number input
            }

            const decimalSeparator = value.includes('.') ? '.' : (value.includes(',') ? ',' : null);
    
            if (decimalSeparator) {
                const parts = value.split(decimalSeparator);
                if (parts[1] && parts[1].length >= INPUT_BLOCK_SIZE) {
                    event.preventDefault();
                }
            }
          }
        }}
        error={handleInputErrors()}
      />

      {isOrderTypeFieldsVisible(securityTypeCode) && (
        <>
          <div className="grid grid-flow-col gap-2">
            <LabeledDiv
              label={t("tradingModal.orderType")}
              className="text-xl font-semibold text-gray-700"
            >
              <Select
                value={orderType}
                options={orderTypeOptions}
                onChange={setOrderType}
              />
            </LabeledDiv>

            <LabeledDiv
              label={t("tradingModal.orderExpiration")}
              className="text-xl font-semibold text-gray-700"
            >
              <Select
                value={orderExpiration}
                options={orderExpirationOptions}
                onChange={setOrderExpiration}
              />
            </LabeledDiv>
          </div>

          {orderType?.id === EOrderType.LIMIT_ORDER && <LabeledDiv
            label={securityTypeCode && [SecurityTypeCode.EQUITY, SecurityTypeCode.ETF].includes(securityTypeCode) 
              ? t("tradingModal.limitPrice") : t("tradingModal.percentageOfNominalValue")}
            className="text-xl font-semibold text-gray-700"
          >
            <Input
                type="text"
                value={stringUnitPrice}
                onChange={(event) => {
                  handleNumberInputEvent(event, setStringUnitPrice, 0, undefined, 3);
                }}
                onPaste={(event) => {
                  handleNumberPasteEvent(event, setStringUnitPrice, 0, undefined, 3);
                }}
                onKeyDown={(event) => {
                  const input = event.target as HTMLTextAreaElement;
                  const value = input.value;
                  const key = event.key;
              
                  //prevent round up decimals
                  if (!isNaN(+key) && key !== ' ') {
                    const selectionStart = input.selectionStart;
                    const selectionEnd = input.selectionEnd;
            
                    // Check if the entire input value is selected
                    if (selectionStart === 0 && selectionEnd === value.length) {
                        return; // Allow number input
                    }
        
                    const decimalSeparator = value.includes('.') ? '.' : (value.includes(',') ? ',' : null);
            
                    if (decimalSeparator) {
                        const parts = value.split(decimalSeparator);
                        if (parts[1] && parts[1].length >= 3) {
                            event.preventDefault();
                        }
                    }
                  }
                }}
                label={""}
                maxLength={15}
            />
          </LabeledDiv>}
        </>
      )}

      {canToggleTradeType && (
        <>
          <div className="flex overflow-hidden font-medium leading-5 bg-gray-50 rounded-md divide-x ring-1 shadow-sm pointer-events-auto select-none divide-slate-400/20 text-[0.8125rem] ring-slate-700/10">
            <button
              className={`text-center cursor-pointer py-2 px-4 flex-1 ${
                isTradeInUnits ? "bg-gray-200" : ""
              }`}
              onClick={() => {
                setIsTradeInUnits(true);
              }}
            >
              {t("tradingModal.unitsButtonLabel")}
            </button>

            <button
              className={`text-center cursor-pointer py-2 px-4 flex-1 ${
                !isTradeInUnits ? "bg-gray-200" : ""
              }`}
              onClick={() => {
                setIsTradeInUnits(false);
              }}
            >
              {t("tradingModal.tradeAmountButtonLabel")}
            </button>
          </div>
        </>
      )}

      <hr />
      <div className="flex flex-col gap-4 items-stretch ">
        <div>
          <LabeledDivFlex
              alignText="center"
              tooltipContent={tradeAmountTooltip() || blockSizeTradeAmountError}
              id="sellOrderModal-tradeAmount"
              label={t("tradingModal.approximateTradeAmount")}
              className="text-2xl font-semibold"
          >
            {t("numberWithCurrency", {
              value: estimatedTradeAmountInPfCurrency || 0,
              currency: portfolioCurrency,
            })}
          </LabeledDivFlex>
          <div className="text-xs text-center text-gray-600">
            {t("tradingModal.additionalCosts")}
          </div>
        </div>
        <Button
            disabled={disableSellButton()}
            isLoading={submitting}
            onClick={async () => {
              setSubmitting(true);
            const response = await handleSell();
            if (response) {
              onClose();
            }
          }}
        >
          {t("tradingModal.sellModalHeader")}
        </Button>
      </div>
    </div>
  );
};
